<template>
    <v-btn class="whatsapp-icon" fab bottom right fixed @click="redirectToWhatsapp">
        <v-icon>mdi-whatsapp</v-icon>
    </v-btn>
</template>
  
<script>
export default {
    methods: {
        redirectToWhatsapp() {
            const whatsappUrl = `https://api.whatsapp.com/send?1=pt_BR&phone=554830546660`;
            window.open(whatsappUrl, '_blank');
        }
    }
};

</script>
  
<style scoped>
.whatsapp-icon {
    width: 10px !important;
    height: 10px !important;
    color: white !important;
    background-color: #009986 !important;
    border-radius: 50% !important;
    padding: 25px !important;
    margin-right: 70px;
}
</style>