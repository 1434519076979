<template>
    <v-container style="margin-bottom: -250px;">
        <br>
        <br>
        <v-row class="text-left">
            <v-col>
                <v-heading class="mt-6 title-page text-uppercase" style="margin-top: 20px;">Solicitação de PPP</v-heading>
            </v-col>
            
            <v-col class="text-right" cols="6" sm="8">
                <v-btn color="primary" dark class="findButton">
                    <v-icon left>mdi-magnify</v-icon>
                    Consultar andamento da solicitação
                </v-btn>
            </v-col>
        </v-row>
        <br>
                     
        <v-form @submit.prevent="submitForm">
             
            <v-row  class="formContact">
                <v-col md="12" class="formFields">
                    <v-text-field v-model="formData.nome" label="Nome Completo" outlined dense required :rules="[$rules.required]"></v-text-field>
                </v-col>
        
                <v-col md="6" class="formFields">
                    <v-text-field v-model="formData.cpf" label="CPF" outlined dense required :rules="[$rules.required]"></v-text-field>
                </v-col>
                    
                <v-col md="6" class="formFields">
                    <v-text-field v-model="formData.telefone" label="Telefone" outlined dense required :rules="[$rules.required]"></v-text-field>
                </v-col>
                    
                <v-col md="6" class="formFields">
                    <v-text-field v-model="formData.email" label="E-mail" outlined dense required :rules="[$rules.required]"></v-text-field>
                </v-col>
        
                <v-col md="6" class="formFields">
                    <v-text-field v-model="formData.pis" label="Número do PIS" outlined dense required :rules="[$rules.required]"></v-text-field>
                </v-col>
        
                <v-col md="12" class="formFields">
                    <v-select 
                        v-model="formData.empresa" 
                        :items="customerCompanies" 
                        label="Relacionado à Empresa"
                        item-text="name"
                        outlined
                        dense
                        required
                        :rules="[$rules.required]"
                        hint="Selecione uma empresa">
                    </v-select>
                </v-col>

                <v-col md="12" class="formFields">
                    <v-file-input accept="image/png, image/jpeg, application/pdf" prepend-icon="" 
                        outlined counter dense label="Imagem RG/CNH"
                        required :rules="[$rules.required, $rules.fileSize(4)]"
                        hint="O arquivo não pode ser superior a 4MB" hide-details="auto"
                        :clearable="true" @change="rgImageFile($event)" ref="logoFile">
                    </v-file-input>
                </v-col>

                <v-col md="12">
                    <p>
                        Agora envie a cópia das seguintes folhas da CTPS: <strong>folha com assinatura do trabalhador / 
                        folha com dados do colaborador / folha com vínculo empregatício.</strong>
                    </p>
                </v-col>

                <v-col md="12" class="formFields">
                    <v-file-input v-model="ctpsFiles" accept="image/png, image/jpeg, application/pdf"
                        small-chips :show-size="1000" counter outlined placeholder="Selecione os arquivos"
                        multiple clearable dense prepend-icon="" label="Imagens CTPS" @change="ctpsFileAdded"
                        required :rules="[$rules.required]" hide-details="auto">
                        <template v-slot:selection="{ text, index, ctpsFile }">
                            <v-chip small text-color="white" color="#295671" close @click:close="ctpsFileRemove(index)">
                                {{ text }}
                            </v-chip>
                        </template>
                    </v-file-input>
                </v-col>

                <v-col md="12" class="formFields">
                    <v-file-input v-model="docsFiles" accept="image/png, image/jpeg, application/pdf"
                        small-chips :show-size="1000" counter outlined placeholder="Selecione os arquivos"
                        multiple clearable dense prepend-icon="" label="Outros Documentos" @change="docsFileAdded"
                        required :rules="[$rules.required]" hide-details="auto">
                        <template v-slot:selection="{ text, index, docsFile }">
                            <v-chip small text-color="white" color="#295671" close @click:close="docsFileRemove(index)">
                                {{ text }}
                            </v-chip>
                        </template>
                    </v-file-input>
                </v-col>

                <v-col>
                    <p>
                        <strong>Atenção! O tamanho máximo dos anexos enviados não deve exceder a 90MB.</strong>
                    </p>
                </v-col>
                    
                <v-col md="12" class="formFields">
                    <v-textarea v-model="formData.mensagem" label="Mensagem" outlined required :rules="[$rules.required]" rows="4" ></v-textarea>
                </v-col>

                <v-col class=" text-uppercase text-right">
                    <v-btn type="submit" class="sendButton"  data-attach-loading>
                        Enviar
                    </v-btn>
                </v-col>
                  
            </v-row>
        </v-form>

        <v-row>
            <whatsButton/>
        </v-row>
        
        <v-row>
            <topButton/>
        </v-row>

    </v-container> 
</template>

<script>

    import 'moment/locale/pt-br';
   // import customerCompanies from "@/common/data/customer-companies.data";
    import topButton from '@/components/layout/GoToTopButton.vue';
    import whatsButton from '@/components/layout/WhatsAppButton.vue';

    export default {
        components: {
            topButton,
            whatsButton,
        },
        
        data() {
            return {
                formData: {
                    nome: null,
                    cpf: null,
                    telefone: null,
                    email: null,
                    pis: null,
                    empresa: null,
                    mensagem: null,
                },
                
              //  customerCompanies: customerCompanies,
                ctpsPreviousFiles: [],
                ctpsFiles: [],
                docsPreviousFiles: [],
                docsFiles: []
            };
        },
        watch: {
            ctpsFiles(val) {
                this.ctpsPreviousFiles = val
            },
            docsFiles(val) {
                this.docsPreviousFiles = val
            },
        }, 


        mounted: function () {
        },

        created() {
            this.$ga.event({
                eventCategory: 'page',
                eventAction: 'action',
                eventLabel: 'click',
                eventValue: 'contact'
            })
        },

        methods: {

            submitForm() {
        
            // this.clearForm();
            },

            clearForm() {
        
                this.formData = {
                    nome: null,
                    cpf: null,
                    telefone: null,
                    email: null,
                    pis: null,
                    empresa: null,
                    mensagem: null,
                };
            },

            rgImageFile(file) {
                this.rgImageFile = file;
            },
            
            ctpsFileRemove (index) {
                this.ctpsFiles.splice(index, 1)
            },
            ctpsFileAdded () {
                if (this.ctpsPreviousFiles.length > 0) {
                this.ctpsFiles.push(...this.ctpsPreviousFiles)
                };
            },
            docsFileRemove (index) {
                this.docsFiles.splice(index, 1)
            },
            docsFileAdded () {
                if (this.docsPreviousFiles.length > 0) {
                this.docsFiles.push(...this.docsPreviousFiles)
                };
            },
        },

    };

</script>

<style lang="scss">

    .title-page {
        color: var(--v-primary-base);
        font-family: "Raleway" !important;
        font-size: 30px !important;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5;
        
    }

    p {
        text-align: justify;
    }

    .formFields {
        width: 100%; 
        margin-bottom: -30px;
    }

    .formContact {
        width: 75%;
        margin-right: -15px;
        margin-left: 15px;
    }

    .sendButton{
        margin-right: 0px !important;
        margin-top: 1px !important;
        font-size: 18px !important;
        width: 150px;
        height: 50px !important;
        color: white !important; 
        background-color: #009986 !important;
        transition: background-color 0.3s ease;
    }

    .sendButton:hover {
    background-color: #0077cc !important;
    }

    .findButton{
        font-family: "Raleway" !important; 
        margin-right: 450px !important;
        margin-top: 1px !important;
        font-size: 10px !important;
        width: 300px;
        height: 40px !important;
        color: white !important; 
        transition: background-color 0.3s ease;
    }

</style>