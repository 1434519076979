const data = [
  { 
      id: 1,
      name: "Principais Documentos",
  },
  { 
      id: 2,
      name: "Relatório de Atividades",
  },
  { 
      id: 3,
      name: "Atas",
  },
  { 
      id: 4,
      name: "Relatório de Incidentes",
  },
  { 
      id: 5,
      name: "Relatório de Ofícios",
  },
  { 
      id: 6,
      name: "Relatório de Verificação de Créditos",
  },
]

export default [...data]
