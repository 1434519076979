<template>
  <div class="lightViolet">
    <v-breadcrumbs :items="items"></v-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: 'JudicialRecoveryNavLinks',
  data() {
    return {
      items: [
        { text: 'Brizola e Japur', href: 'http://localhost:8080/#/home' },
        { text: 'Recuperações Judiciais', href: 'http://localhost:8080/#/casos/recuperacoes' },
        {
          text:
            'SEMEATO S.A. INDÚSTRIA E COMÉRCIO, ROSSATO ADMINISTRAÇÃO E PARTICIPAÇÕES LTDA., METALÚRGICA SEMEATO LTDA., CIA SEMEATO DE AÇOS – C.S.A., e SEMEATO ARMAS INDUSTRIA E COMERCIO LTDA. - SEMEATO S.A. INDÚSTRIA E COMÉRCIO, ROSSATO ADMINISTRAÇÃO E PARTICIPAÇÕES LTDA., METALÚRGICA SEMEATO LTDA. e CIA SEMEATO DE AÇOS – C.S.A.',
          href: '#'
        }
      ]
    }
  }
}
</script>
