<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col>
                    <div class="font-weight-bold mt-6 title-page">Recuperação de Senha</div>
                    <p class="mt-2">
                        Insira a sua nova senha abaixo.
                    </p>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="validForm">
                <v-row>
                    <v-col>
                        <v-text-field dense outlined label="Nova senha" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            required :rules="[$rules.required, $rules.strongPassword]"
                            :type="showPassword ? 'text' : 'password'" v-model="dataset.data.password"
                            @click:append="showPassword = !showPassword" hide-details="auto" class="mb-4"
                            id="password"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field dense outlined label="Confirmação da nova senha"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" required :rules="[
                                $rules.required,
                                dataset.data.password ===
                                dataset.data.passwordConfirmation ||
                                'As senhas não coincidem.',
                            ]" :type="showPassword ? 'text' : 'password'" v-model="dataset.data.passwordConfirmation"
                            @click:append="showPassword = !showPassword" hide-details="auto" class="mb-4"
                            id="password"></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-text>
            <v-btn color="primary" @click.native="finish" :loading="loading">Concluir</v-btn>
            <v-btn color="grey" text @click.native="goBack">Cancelar</v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import 'moment/locale/pt-br';

import { eventHub } from "@/main";

import axios from "axios"

import router from '../../router';

export default {
    data: () => ({
        loading: false,
        validForm: false,
        showPassword: false,
        dataset: {
            data: {}
        }
    }),

    props: {
        token: null
    },

    mounted: function () {
    },

    created() {
        this.dataset.data = {}
    },

    methods: {

        goBack() {
            eventHub.$emit('DIALOG-CHANGE-PASSWORD', false)
        },

        async finish() {

            if (!this.$refs.form.validate()) return;

            let data = {
                password: this.dataset.data.password,
                token: this.token
            };

            this.loading = true;

            try {
                const result = await axios.post('/api/users/change-password', data);

                if (!result.data.status) throw new Error();

                await this.$dialog.confirm({
                    text: 'Senha alterada com sucesso!',
                    title: 'Sucesso',
                    actions: {
                        true: 'OK'
                    }
                })

                this.goBack();

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataset.data = {}
                this.loading = false;
            }
        },

    }
};
</script>

<style lang="scss">
.title-page {
    color: var(--v-primary-base);
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

p {
    text-align: justify;
}
</style>