const data = [
    {
        //id:1,
        question: 'Como é possível consultar editais no site eletrônico do Tribunal de Justiça do Rio Grande do Sul?',
        answer: `Na página inicial de www.tjrs.jus.br, acesse o ícone "processos", constante no lado esquerdo da página. Após, selecione "Diário da Justiça Eletrônico". Indique o número do processo no campo respectivo e selecione o caderno "Editais 1º e 2º Grau". Nesta busca, será possível acessar os ediatis dos últimos 02 (dois) anos. 
                Se preferir, acesse: http://www.tjrs.jus.br/busca/?tb=dj.`,
        showAnswer: false,

    }, 
    {
        //id:2,
        question: 'Como são realizadas as intimações aos credores?',
        answer: 'Tanto nas Recuperações Judiciais, como nas Falências, as intimações aos credores são realizadas mediante a publicação de editais. Como regra, não há o cadastramento de Advogados para inclusão em notas de expediente ou tampouco intimações pessoais de credores.',
        showAnswer: false,
    },
    {
        //id:3,
        question: 'Em caso de não concordância com o plano de recuperação apresentado, o que o credor deve fazer?',
        answer: 'A medida neste caso é a apresentação de Objeção, nos autos da Recuperação Judicial. O prazo para tal é de 30 (trinta dias), a contar da publicação do edital da relação de credores elaborado pela Administradora Judicial ou do aviso de recebimento do plano de recuperação (da data de publicação do último destes).',
        showAnswer: false,
    },
    {
       // id:4,
        question: 'Em caso de recuperação judicial, quem elabora o plano de recuperação? ',
        answer: 'O plano de recuperação é elaborado pela empresa devedora, sem ingerência da Administradora Judicial.',
        showAnswer: false,
    },
    {
        //id:5,
        question: 'Como funciona a habilitação/divergência de créditos trabalhistas?',
        answer: 'Segundo o Art. 6º, § 2º, da Lei 11.101/2005, a competência para a apuração do valor devido a credores da relação de trabalho é da Justiça do Trabalho. Portanto, tão logo o crédito seja definido por aquela Justiça Especializada, deve ser solicitada a confecção de Certidão específica, a ser apresentada à Administradora Judicial ou no processo/procedimento de falência ou Recuperação Judicial. Atenção: a Certidão deverá apresentar o valor atualizado do débito até a data do pedido de Recuperação Judicial ou da decretação da falência, conforme for o caso.',
        showAnswer: false,
    },
    {
       // id:6,
        question: 'O não recebimento da correspondência postada pela Administradora Judicial (Art. 22, I, “a”, da Lei 11.101/2005) leva à nulidade do processo/procedimento? ',
        answer: 'Não. As correspondências são consideradas uma comunicação extra, valendo como intimação o constante nos editais.',
        showAnswer: false,
    },
    {
       // id:7,
        question: 'Se o crédito está relacionado de forma correta no edital de processamento da Recuperação Judicial ou no edital de decretação da Falência, é necessário apresentação habilitação/divergência de crédito? ',
        answer: 'Não. Nada impede que o credor apresente manifestação indicando a sua concordância com o crédito, mas a ausência de tal manifestação não importará em perda do crédito. O credor deverá ficar atento se o crédito constará futura relação de credores a ser apresentada pela Administradora Judicial, apresentando Impugnação no caso de algum equívoco.',
        showAnswer: false,
    },       
    {
        //id:8,
        question: 'A habilitação/divergência de crédito deve ser apresentada à Administradora Judicial ou protocolada no processo?',
        answer: 'Dentro do prazo de 15 dias indicado no Art. 7º, § 1º, da Lei 11.101/2005, a apresentação deve ser realizada diretamente à Administradora Judicial. As habilitações retardatárias devem ser apresentadas ao juízo, mediante a distribuição de incidente processual e pagamento de custas processuais.',
        showAnswer: false,
    },
    {
        //id:9,
        question: 'Até que data deve ser atualizado o valor devido para a apresentação de habilitação/divergência de crédito?',
        answer: `No caso de Recuperação Judicial, até a data do pedido de Recuperação (petição inicial) apresentado pela empresa. Já no caso de processo de Falência, a data a ser considerada é a da decretação falência (sentença). 
                Embora a Lei 11.101/2005 não trate especificamente sobre a data de atualização dos créditos do caso de Autofalência (pedido de falência apresentado pela própria empresa devedora), o entendimento é o de que a atualização deve ser realizada até a data da distribuição do pedido de Autofalência. Esse entendimento decorre do fato de que a empresa devedora já deve incluir a sua relação de credores quando distribui o seu pedido de autofalência, sendo este o parâmetro inicial para a realização do futuro quadro geral de credores.`,
        showAnswer: false,
    },
    {
        //id:10,
        question: 'Quais documentos devem acompanhar a habilitação/divergência de crédito? ',
        answer: `Como o objetivo é o de comprovar o crédito, é necessário apresentar os documentos comprobatórios do crédito. O parágrafo único do Art. 9º, da Lei 11.101/2005, indica que "os títulos e documentos que legitimam os créditos deverão ser exibidos no original ou por cópias autenticadas se estiverem juntados em outro processo".
                Além disso, se o habilitante é uma pessoa física, deve ser anexada cópia de documento de identificação; no caso de pessoas jurídicas, os atos constitutivos (contrato social ou estatuto) devem acompanhar o pedido, bem como documentos que comprovem a condição de representante legal daquele que assina o pedido. 
                No caso de habilitação/divergência de crédito apresentada por meio de Advogado, o pedido deverá ser acompanhado de procuração.`,
        showAnswer: false,
    },
    {
        //id:11,
        question: 'Qual o prazo para a apresentação de habilitação/divergência de crédito à Administradora Judicial?',
        answer: 'O Art. 7º, § 1º, da Lei 11.101/2005, indica que as habilitações e as divergências devem ser apresentadas diretamente à Administradora Judicial, dentro do prazo de 15 dias a contar da publicação do edital de processamento da Recuperação Judicial ou do edital de decretação da Falência, conforme for o caso. Esse prazo é considerado como de direito material (e não processual), o que afasta a aplicação das regras do novo Código de Processo Civil para a sua contagem.',
        showAnswer: false,
    },
    {
        question: 'O que deve constar em uma habilitação/divergência de crédito?',
        answer: 'O Art. 9 da Lei 11.101/2005 indica de forma taxativa o que deve constar na habilitação/divergência de crédito ("Art. 9º. A habilitação de crédito realizada pelo credor nos termos do art. 7º, § 1º, desta Lei deverá conter: I – o nome, o endereço do credor e o endereço em que receberá comunicação de qualquer ato do processo; II – o valor do crédito, atualizado até a data da decretação da falência ou do pedido de recuperação judicial, sua origem e classificação; III – os documentos comprobatórios do crédito e a indicação das demais provas a serem produzidas; IV – a indicação da garantia prestada pelo devedor, se houver, e o respectivo instrumento; V – a especificação do objeto da garantia que estiver na posse do credor. Parágrafo único. Os títulos e documentos que legitimam os créditos deverão ser exibidos no original ou por cópias autenticadas se estiverem juntados em outro processo.").',
        showAnswer: false,
    },
    {
        question: 'Qual a diferença entre habilitação de crédito e divergência de crédito?',
        answer: 'A rigor, deve ser realizada habilitação de crédito se alguma obrigação do devedor não foi relacionada. Na grande maioria das vezes, a habilitação se dá quando o credor não foi relacionado pela empresa em sua relação de credores. Já a divergência de crédito diz respeito à inconformidade quanto ao valor relacionado pela empresa ou a classificação oferecida ao crédito.',
        showAnswer: false,
    },
    {
        question: 'A Administradora Judicial é a Advogada da devedora (empresa falida ou em recuperação judicial)?',
        answer: 'Não. A Administradora Judicial é uma auxiliar do(a) Juiz(a), sendo que as suas atribuições estão elencadas no Art. 22 da Lei 11.101/2005. No caso de falência, é sua atribuição representar a massa falida em todos os feitos e situações que envolvam a massa, podendo inclusive constituir Advogado(a) mediante autorização do(a) Juiz(a). Já no caso de Recuperação Judicial, suas atribuições relacionam-se mais à fiscalização, sem poderes de gestão (exceto quando os administradores da empresa forem afastados e tão somente até a nomeação de um(a) Gestor(a) Judicial).',
        showAnswer: false,
    },
    
]

  
export default [...data]
  