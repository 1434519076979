const data = [
    { 
        id: 1, 
        name: 'Documento de identificação', 
        status: [
            {
                id: 1,
                name: "Válido",
                approved: true,
                color: "#4CAF50"
            },
            {
                id: 2,
                name: "Inválido",
                approved: false,
                color: '#f59a9a',
            },
        ] 
    },
    { 
        id: 2, 
        name: 'Procuração',
        status: [
            {
                id: 1,
                name: "Válida para participar da RJ",
                approved: true,
                color: "#4CAF50"
            },
            {
                id: 2,
                name: "Válida para participar de qualquer AGC",
                approved: true,
                color: "#4CAF50"
            },
            {
                id: 3,
                name: "Válida para participar somente em uma AGC",
                approved: true,
                color: "#4CAF50"
            },
            {
                id: 4,
                name: "Não tem poderes para representar na RJ",
                approved: false,
                color: '#f59a9a'
            },
            {
                id: 5,
                name: "Não tem poderes para participar da AGC",
                approved: false,
                color: '#f59a9a'
            },
            {
                id: 6,
                name: "Não está atualizada",
                approved: false,
                color: '#f59a9a'
            },
        ]
    },
    { 
        id: 3, 
        name: 'Documentos Comprobatórios',
        status: [
            {
                id: 1,
                name: "Válido",
                approved: true,
                color: "#4CAF50"
            },
            {
                id: 2,
                name: "Inválido",
                approved: false,
                color: '#f59a9a'
            },
        ] 
    },
    { 
        id: 5, 
        name: 'Petição',
        status: [
            {
                id: 1,
                name: "Válido",
                approved: true,
                color: "#4CAF50"
            },
            {
                id: 2,
                name: "Inválido",
                approved: false,
                color: '#f59a9a'
            },
        ] 
    },
    { 
        id: 6, 
        name: 'Planilha de Cálculos',
        status: [
            {
                id: 1,
                name: "Válido",
                approved: true,
                color: "#4CAF50"
            },
            {
                id: 2,
                name: "Inválido",
                approved: false,
                color: '#f59a9a'
            },
        ] 
    },
    { 
        id: 4, 
        name: 'Outros',
        status: [
            {
                id: 1,
                name: "Válido",
                approved: true,
                color: "#4CAF50"
            },
            {
                id: 2,
                name: "Inválido",
                approved: false,
                color: '#f59a9a'
            },
        ] 
    },
];

export default [...data];
