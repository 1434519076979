const data = [
  { id: 0, name: 'Aberta' },
  { id: 1, name: 'Fechada' },
  { id: 2, name: 'Interrompida' },
  { id: 3, name: 'Suspensa' },
  { id: 4, name: 'Finalizada' },
  { id: 5, name: 'Em andamento' },
]

export default [...data]
