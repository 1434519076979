const data = [
    { id: '', name: 'Todos' },
    { id: 1, name: 'AC' },
    { id: 2, name: 'AL' },
    { id: 3, name: 'AP' },
    { id: 4, name: 'AM' },
    { id: 5, name: 'BA' },
    { id: 6, name: 'CE' },
    { id: 7, name: 'DF' },
    { id: 8, name: 'ES' },
    { id: 9, name: 'GO' },
    { id: 10, name: 'MA' },
    { id: 11, name: 'MT' },
    { id: 12, name: 'MS' },
    { id: 13, name: 'MG' },
    { id: 14, name: 'PA' },
    { id: 15, name: 'PB' },
    { id: 16, name: 'PR' },
    { id: 17, name: 'PE' },
    { id: 18, name: 'PI' },
    { id: 19, name: 'RJ' },
    { id: 20, name: 'RN' },
    { id: 21, name: 'RS' },
    { id: 22, name: 'RO' },
    { id: 23, name: 'RR' },
    { id: 24, name: 'SC' },
    { id: 25, name: 'SP' },
    { id: 26, name: 'SE' },
    { id: 27, name: 'TO' },
    
]
  
export default [...data]
  