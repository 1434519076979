<template>
  <v-container fluid class="pa-0 pt-4" style="margin-bottom: -250px;">
    <div class="image">
      <video preload="true" loop autoplay muted class="video">
        <source src="@/assets/BeJ.mp4" type="video/mp4" />
      </video>
      <!-- <v-img src="@/assets/home_logo_large.jpeg" class="fill-height image-home"></v-img> -->
    </div>

    <div style="margin-top: -7px;">
      <v-row no-gutters md="12" class="row-map">
        <v-col lg="6">
          <div class="video-container">
            <!-- <video preload="true" loop autoplay muted class="video hidden-md-and-down">
              <source src="@/assets/home-video.mp4" type="video/mp4" />
            </video> -->
            <v-img src="@/assets/mapa-BeJ.png" class="fill-height image-home"></v-img>
          </div>
        </v-col>

        <v-col cols="12" lg="6" justify="center" class="d-flex flex-column px-2 py-10">
          <v-row no-gutters class="mb-0">
            <v-col>
              <v-card class="item-number text-center h-100 white--text  px-10">
                <v-card-text class="d-flex flex-column align-center justify-center h-100">
                  <span class="text-number d-block mediumWhite--text mt-4">{{ cardNumbers[0].number }}</span>
                  <span class="text-label d-block mediumWhite--text">{{ cardNumbers[0].label }}</span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="item-number text-center h-100 white--text  px-10">
                <v-card-text class="d-flex flex-column align-center justify-center h-100">
                  <span class="text-number d-block mediumWhite--text mt-4">{{ cardNumbers[1].number }}</span>
                  <span class="text-label d-block mediumWhite--text">{{ cardNumbers[1].label }}</span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="item-number text-center h-100 white--text  px-10">
                <v-card-text class="d-flex flex-column align-center justify-center h-100">
                  <span class="text-number d-block mediumWhite--text mt-4">{{ cardNumbers[3].number }}</span>
                  <span class="text-label d-block mediumWhite--text">{{ cardNumbers[3].label }}</span>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- <v-col v-for="(card, index) in cardNumbers" :key="index" cols="12" md="6" class="pb-0">
              <v-card class="item-number text-center h-100 white--text  px-10">
                <v-card-text class="d-flex flex-column align-center justify-center h-100">
                  <v-img :src="card.image" class="image-card" contain></v-img>
                  <span class="text-number d-block mediumWhite--text mt-4">{{ card.number }}</span>
                  <span class="text-label d-block mediumWhite--text">{{ card.label }}</span>
                </v-card-text>
              </v-card>
            </v-col> -->
          </v-row>
          <v-row no-gutters class="mb-0">
            <v-col>
              <v-card class="item-number text-center h-100 white--text  px-10">
                <v-card-text class="d-flex flex-column align-center justify-center h-100">
                  <span class="text-number d-block mediumWhite--text mt-4">{{ cardNumbers[2].number }}</span>
                  <span class="text-label d-block mediumWhite--text">{{ cardNumbers[2].label }}</span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <div class="py-10 limiter-content">
      <most-accessed-processes></most-accessed-processes>
    </div>

    <div class="custom-divider"></div>

    <team-section></team-section>

    <div class="custom-divider"></div>

    <calendar-section class="pt-10"></calendar-section>

    <v-row>
      <whatsButton />
    </v-row>

    <v-row>
      <topButton />
    </v-row>

    <v-dialog v-if="recoveryToken" persistent v-model="dialogRecoverPassword" :width="600">
      <recover-password-component :token="recoveryToken"></recover-password-component>
    </v-dialog>

    <v-dialog v-model="dialogPopup" :width="500">

      <v-card>
        <v-carousel v-if="bannerImages.length > 1" cycle interval="5000" hide-delimiters height="auto"
          show-arrows-on-hover>
          <v-carousel-item v-for="(image, index) in bannerImages" :key="index" :src="image"></v-carousel-item>
        </v-carousel>
        <v-img v-else :src="bannerImages[0]" height="auto" width="500"></v-img>
        <v-card-actions>
          <v-btn color="primary" @click="dialogPopup = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

  </v-container>
</template>

<script>
import 'moment/locale/pt-br';
import axios from 'axios';

import { eventHub } from '@/main';

import topButton from '@/components/layout/GoToTopButton.vue';
import whatsButton from '@/components/layout/WhatsAppButton.vue';
import MostAccessedProcesses from '@/views/sections/home/MostAccessedProcesses.vue';
import RecoverPassword from '@/views/user/RecoverPassword.vue';
// import CalendarSection from '@views/sections/home/CalendarSection.vue'
import TeamSection from '@/views/Team.vue';
import CalendarSection from '@/views/sections/home/CalendarSection.vue';

export default {
  components: {
    topButton,
    whatsButton,
    'most-accessed-processes': MostAccessedProcesses,
    'recover-password-component': RecoverPassword,
    CalendarSection,
    TeamSection
  },

  data: () => ({
    user: null,
    dataList: [],
    recoveryToken: null,
    dialogRecoverPassword: false,
    dialogPopup: false,
    bannerImages: [],
    cardNumbers: [
      {
        id: 1,
        number: 0,
        label: 'Número de\ncasos ativos',
        image: require('@/assets/images/home/icon-number-1.png')
      },
      {
        id: 2,
        number: 0,
        label: 'Número de credores',
        image: require('@/assets/images/home/icon-number-2.png')
      },
      {
        id: 3,
        number: 0,
        label: 'Total do passivo administrado',
        image: require('@/assets/images/home/icon-number-3.png')
      },
      {
        id: 4,
        number: 0,
        label: 'Comarcas atendidas',
        image: require('@/assets/images/home/icon-number-4.png')
      }
    ]
  }),

  mounted() {
    this.loadBanner();
  },

  created() {
    this.$ga.event({
      eventCategory: 'page',
      eventAction: 'action',
      eventLabel: 'click',
      eventValue: 'home'
    });

    eventHub.$on('DIALOG-CHANGE-PASSWORD', value => {
      if (!value) this.$router.replace('/home');

      this.dialogRecoverPassword = value;
    });

    eventHub.$on('DIALOG-REGISTER', value => {
      if (!value) this.$router.replace('/home');
    });

    if (this.$route.query.token) {
      this.verifyAccount(this.$route.query.token);
    }

    if (this.$route.query.recoveryToken) {
      this.verifyRecoverPasswordToken(this.$route.query.recoveryToken);
    }

    this.load();
  },

  methods: {
    openRegister() {
      eventHub.$emit('DIALOG-REGISTER', true);
    },

    async loadBanner() {

      try {
        const result = await axios.get('/api/banners/active-list');

        this.bannerImages = result.data.content.data;

        if (this.bannerImages.length) this.dialogPopup = true;

      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
      }

    },

    async verifyAccount(token) {
      if (!token) return;

      this.loading = true;

      try {
        const normalizedToken = decodeURIComponent(token.toString().replaceAll(' ', '+'));

        const result = await axios.post('/api/users/verify-account', { token: normalizedToken });

        if (!result.data.status) throw new Error();

        await this.$dialog.confirm({
          text: 'Conta verificada com sucesso, realize o login para acessar a "Área do Credor"!',
          title: 'Sucesso',
          actions: {
            true: 'OK'
          }
        });

        this.$router.replace('/home');
      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
      } finally {
        this.loading = false;
      }
    },

    async verifyRecoverPasswordToken(token) {
      if (!token) return;

      this.recoveryToken = decodeURIComponent(token.toString().replaceAll(' ', '+'));

      this.dialogRecoverPassword = true;
    },

    formatValue(value) {
      let val = (value / 1).toFixed(2).replace('.', ',');
      return 'R$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },

    async load() {
      try {
        const result = await axios.get('/api/dashboard/analytic-clients');

        this.cardNumbers[0].number = result.data.content.total_cases
          ? result.data.content.total_cases.toLocaleString()
          : 0;
        this.cardNumbers[1].number = result.data.content.total_creditors
          ? result.data.content.total_creditors.toLocaleString()
          : 0;
        this.cardNumbers[2].number = result.data.content.total_value
          ? this.formatValue(result.data.content.total_value)
          : 0;
        this.cardNumbers[3].number = result.data.content.total_districts || 0;
      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
      }
    },

  }
};
</script>

<style lang="scss">
.title-page {
  color: var(--v-primary-base);
  font-family: 'Raleway' !important;
  font-size: 30px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}

.image {
  width: 100vw;
  overflow: hidden;
  z-index: 999;
}

.image-card {
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
}

.text-number {
  font-family: 'Metropolis' !important;
  font-size: 32px;
  font-weight: SemiBold;
  padding-bottom: 10px;
  text-align: center;
}

.text-label {
  font-family: 'Metropolis' !important;
  font-size: 14px;
  font-weight: normal;
  text-transform: lowercase;
  letter-spacing: 0.18em;
  padding-bottom: 30px;
}

.item-number {
  padding: 0;
  margin: 0;
  background-color: var(--v-primary-base) !important;
  border-radius: 0 !important;
}

.video-container {
  max-width: 100%;
  max-height: 100%;
}

.video {
  width: 100%;
  height: auto;
}

.custom-divider {
  height: 30px;
  background-color: var(--v-primary-base);
}

.row-map {
  background-color: var(--v-primary-base) !important;
}

.h-100 {
  height: 100%;
}

.image-home {
  @media (max-width: 600px) {
    height: 250px;
  }
}
</style>
