<template>
    <v-container>

        <v-row>
            <v-col>
                <div class="font-weight-bold mt-10 title-page">Envio de Habilitações e Divergências</div>
            </v-col>
        </v-row>
        
        
       
        
       



    </v-container>
</template>

<script>
import 'moment/locale/pt-br';

import { eventHub } from "@/main"

export default {
    data: () => ({
        user: null,
        dialogPopup: false
    }),

    mounted: function () {
    },

    created() {
        this.$ga.event({
            eventCategory: 'page',
            eventAction: 'action',
            eventLabel: 'click',
            eventValue: 'home'
        })

     
    },

    methods: {
        openRegister() {
            eventHub.$emit("DIALOG-REGISTER", true)
        }
    }
};
</script>

<style lang="scss">
.title-page {
    color: var(--v-primary-base);
    font-family: "Raleway";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}
</style>
