import { cpf, cnpj } from 'cpf-cnpj-validator'
import Vue, { PluginObject } from 'vue'

const isEmpty = value => {
  return value === undefined || value === null || value === ''
}

const rules = {
  required: value => !isEmpty(value) || 'Campo obrigatório',
  arrayRequired: value => !isEmpty(value) || value.length > 0 || 'Campo obrigatório',
  email: value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return isEmpty(value) || pattern.test(value) || 'E-mail inválido'
  },
  phone: value => {
    const pattern = /^\([1-9]{2}\) (?:[2-8]|9[0-9])[0-9]{3}-[0-9]{4}$/
    return isEmpty(value) || pattern.test(value) || 'Celular inválido'
  },
  url: value => {
    const pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}/gm
    return isEmpty(value) || pattern.test(value) || 'URL inválida'
  },
  minlength: (value, length) =>
    isEmpty(value) || value.length >= length || 'Limite mínimo de caracteres',
  maxlength: (value, length) =>
    isEmpty(value) || value.length <= length || 'Limite máximo de caracteres',
  cpf: value => isEmpty(value) || cpf.isValid(value) || 'CPF inválido',
  cnpj: value => isEmpty(value) || cnpj.isValid(value) || 'CNPJ inválido',
  year: value => isEmpty(value) || new RegExp(/^(19|20)\d{2}/).test(value) || 'Ano inválido',
  fixedSizeNumber: digits => {
    return value => {
      //'^.{' + digits + ',6}$'
      let regex = '\\d{' + digits + '}'
      return isEmpty(value) || new RegExp(regex).test(value) || 'Número inválido'
    }
  },
  number: value => isEmpty(value) || /(^\d+$)/.test(value) || 'Número inválido',
  slug: (value, message) =>
    isEmpty(value) || /^[a-z0-9\-._]+$/.test(value) || message || 'Valor inválido',
  strongPassword: value => {
    let result = /[a-z]/.test(value) || 'Senha precisa conter caracteres minúsculos'

    if (result === true) {
      result = /[A-Z]/.test(value) || 'Senha precisa conter caracteres maiúsculos'
    }

    if (result === true) {
      result = /[0-9]/.test(value) || 'Senha precisa conter pelo menos um número'
    }

    if (result === true) {
      result = /\W|_/.test(value) || 'Senha precisa conter pelo menos um caracter especial'
    }

    if (result === true) {
      result = value.length >= 8 || 'Senha deve possuir pelo menos 8 caracteres'
    }

    return result
  },

  fileSize: size => {
    return file => {
      const sizeMB = size * 1024 * 1024
      return !file || file.size <= sizeMB || `Arquivo deve ser inferior a ${size} MB`
    }
  },

  filesSize: size => {
    return files => {
      const sizeMB = size * 1024 * 1024
      return (
        !files ||
        !files.length ||
        files.reduce((sum, file) => (sum += file.size), 0) <= sizeMB ||
        `Total de arquivo deve ser inferior a ${size} MB`
      )
    }
  }
}

const platform = {
  isMobile: () => {
    const platform = navigator.platform.toLowerCase();
    return /(android|webos|iphone|ipad|ipod|blackberry|windows phone)/.test(platform);
  }
}

const masks = {
  cpf: '###.###.###-##',
  cnpj: '##.###.###/####-##',
  zipCode: '#####-###',
  year: '####',
  number: '#',
  plate: 'AAA-#N##',
  phone: '(##) #####-####',
  mac: 'NN:NN:NN:NN:NN:NN'
}

class ValidationPlugin implements PluginObject<any> {
  install = (_Vue: typeof Vue, options?: any) => {
    _Vue.prototype.$rules = rules
    _Vue.prototype.$masks = masks
    _Vue.prototype.$platform = platform
  }
}

const plugin = new ValidationPlugin()
Vue.use(plugin)
